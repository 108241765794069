<script setup lang="ts">
useHead({
  link: [
    {
      rel: "stylesheet",
      href: "/assets/compile/tailwind.css",
    },
  ],
});
</script>

<template>
  <div
    class="tw-min-h-screen tw-flex tw-flex-col tw-bg-nl-yellow-100 tw-text-nl-green-100 tw-antialiased tw-text-body-sm md:tw-text-body"
  >
    <CheckoutSuccessHeader />
    <main>
      <slot />
      <CheckoutSuccessFooter />
    </main>
  </div>
</template>
<style></style>
